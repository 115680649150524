import React, { useRef } from "react";
import Slider from "react-slick";
import logo1 from "../../assets/image/home-3/png/clients/client1.png";
import logo2 from "../../assets/image/home-3/png/clients/client2.png";
import logo3 from "../../assets/image/home-3/png/clients/client3.png";
import logo4 from "../../assets/image/home-3/png/clients/client4.png";
import logo5 from "../../assets/image/home-3/png/clients/client5.png";

const ClientsAndPartners = ({ className, ...rest }) => {
  const elSlider = useRef();
  const sliderSettings = {
    autoplay: true,
    centerMode: true,
    centerPadding: "17%",
    slidesToShow: 3,
    arrows: false,
    touchMove: true,
    infinite: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          centerPadding: "10px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          centerPadding: "80px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 850,
        settings: {
          centerPadding: "10%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 570,
        settings: {
          centerPadding: "30px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  const featureCards = [
    {
      image: logo1,
      title: "Find Leads",
      text: "We’ve helped over 2,500 job seekers to get into the most popular tech teams.",
    },
    {
      image: logo2,
      title: "Team Collaboration",
      text: "We’ve helped over 2,500 job seekers to get into the most popular tech teams.",
    },
    {
      image: logo3,
      title: "Instant Chat",
      text: "We’ve helped over 2,500 job seekers to get into the most popular tech teams.",
    },
    {
      image: logo4,
      title: "Media Manager",
      text: "We’ve helped over 2,500 job seekers to get into the most popular tech teams.",
    },
    {
      image: logo5,
      title: "Dedicated Storage",
      text: "We’ve helped over 2,500 job seekers to get into the most popular tech teams.",
    },
  ];

  return (
    <div className="">
      {/* feature section */}
      <div className="container pt-10 pb-15 pb-lg-20">
        <h2 className="font-size-9 title mb-6 letter-spacing-n83">
          Our Valued Clients and Partners
        </h2>

        {/* feature slider */}
        <div className="row mt-15">
          <div className="col-12" data-aos="zoom-in" data-aos-delay={800}>
            <Slider
              ref={elSlider}
              {...sliderSettings}
              className="l3-feature-slider d-flex"
            >
              {featureCards.map(({ image, title, text }, index) => {
                return (
                  // <Card image={image} title={title} text={text} key={index} />
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "120px", height: "120px" }}
                  >
                    <img
                      width={image === logo4 ? 80 : 130}
                      height={80}
                      style={{ objectFit: "contain" }}
                      src={image}
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsAndPartners;
