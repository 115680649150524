import React from "react";

import imgC from "../../assets/image/home-3/png/students-wtih-dots.png";
import imgW from "../../assets/image/home-3/png/workers-with-dots.png";
// import imgDP from "../../assets/image/home-2/png/dot-pattern-black.png";

const Content = () => {
  return (
    <div className="pb-11 pb-md-15 pb-lg-27">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-6 col-sm-9">
            <div
              className=" mb-13 mb-lg-16"
              data-aos="zoom-in"
              data-aos-duration={500}
            >
              <h2 className="font-size-9 dark-text mb-0 letter-spacing-n83">
                What is UniCloud360?
              </h2>
            </div>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          {/* Right */}
          <div className="col-xl-5 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <div className="img-1">
                <img
                  className="w-100 "
                  src={imgC}
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration={600}
                />
                {/*<div className="img-2 z-index-n1 mt-n13">*/}
                {/*  <img*/}
                {/*    className="w-100"*/}
                {/*    src={imgDP}*/}
                {/*    alt=""*/}
                {/*    data-aos="fade-right"*/}
                {/*    data-aos-duration={900}*/}
                {/*    data-aos-delay={300}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
          {/* Right */}
          {/* Left */}
          <div className="col-xl-7 col-lg-7 col-md-7 col-xs-10">
            <div
              className="mx-lg-14 mx-xl-14"
              data-aos="fade-left"
              data-aos-duration={600}
            >
              <h2 className="font-size-8 light-text">
                <span className="title">UNICLOUD360</span> discovered the most
                significant pain points of higher education institutions
              </h2>
              <p className="heading-default-color mt-5 gray-text font-size-6 line-height-26 mb-4 mb-lg-9">
                With the unmerciful competition in the education domain in Sri
                Lanka, your institution deserves a ubiquitous solution that
                provides faster analytics, automated reporting and a cost-saving
                Information Management System.
                <span className="title"> UNICLOUD360</span> discovered the most
                significant pain points of higher education institutions
              </p>
            </div>
          </div>
          {/* Left End */}
        </div>

        {/*2nd row*/}
        <div className="row align-items-center justify-content-center mt-10">
          {/* Left */}
          <div className="col-xl-7 order-2 order-md-1 d-flex justify-content-center align-items-center col-lg-7 col-md-7 col-xs-10">
            <div
              className="mx-lg-14 h-100 mx-xl-14 d-flex justify-content-between flex-column"
              data-aos="fade-left"
              data-aos-duration={600}
            >
              <p className="heading-default-color mt-5 gray-text font-size-6 line-height-26 ">
                This is why our team allows education service providers to
                utilize an expansive yet seamlessly integrated module-based
                platform to appeal to the potential operational, managerial and
                administrative progress within the education institute itself.
                It is an all-in-one Student Management System that allows
                permitted users to track and trace any operation running in the
                institution with a few simple clicks.
              </p>
              <p className="gray-text font-size-6 line-height-26">
                This solution is mainly developed to optimize each and every
                process that happens during a student's academic life cycle -
                spanning from enrollment to student graduation- by enabling the
                relevant tools, features, functionalities and benefits that
                boost the performance of the institution.
              </p>
            </div>
          </div>
          {/* Left End*/}

          {/* Right */}

          <div className="col-xl-5 order-1 order-md-2 col-lg-5 col-md-5 col-xs-10">
            <div className="about-content-image-group-1 mb-8 mb-lg-0">
              <div className="img-1">
                <img
                  className="w-100 "
                  src={imgW}
                  alt=""
                  data-aos="fade-right"
                  data-aos-duration={600}
                />
              </div>
            </div>
          </div>
          {/* Right End */}
        </div>

        {/*3rd row*/}
        {/*<div className="mt-20">*/}
        {/*  <h2 className="font-size-9 dark-text">What is Ceyentra?</h2>*/}
        {/*  <div className="row align-items-center justify-content-center mt-10">*/}
        {/*    /!* Left *!/*/}
        {/*    <div className="col-xl-5 col-lg-5 col-md-5 col-xs-10">*/}
        {/*      <div className="about-content-image-group-1 mb-8 mb-lg-0">*/}
        {/*        <div className="img-1">*/}
        {/*          <img*/}
        {/*            className="w-100 "*/}
        {/*            src={OurTeam}*/}
        {/*            alt=" "*/}
        {/*            data-aos="fade-right"*/}
        {/*            data-aos-duration={600}*/}
        {/*          />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    /!* Left End*!/*/}

        {/*    /!* Right *!/*/}

        {/*    <div className="col-xl-7 d-flex justify-content-center align-items-center col-lg-7 col-md-7 col-xs-10">*/}
        {/*      <div*/}
        {/*        className="mx-lg-14 h-100 mx-xl-14 d-flex justify-content-between flex-column"*/}
        {/*        data-aos="fade-left"*/}
        {/*        data-aos-duration={600}*/}
        {/*      >*/}
        {/*        <p className="heading-default-color text-justify mt-5 gray-text font-size-5 line-height-26 ">*/}
        {/*          Ceyentra Technologies (Pvt) Ltd was established in 2018 under*/}
        {/*          the influence of experienced engineers from leading*/}
        {/*          universities including University of Moratuwa. Ceyentra is a*/}
        {/*          provider of software development services and consultancy that*/}
        {/*          offers clients around the world with competitively priced*/}
        {/*          outsourced services.With a strong local presence in Sri Lanka,*/}
        {/*          Ceyentra Technologies has founded itself as a well -*/}
        {/*          established and a results driven company which has being led*/}
        {/*          by the industry’s most professional and trained individuals,*/}
        {/*          and is on it’s way to becoming a leading organization that*/}
        {/*          facilitates, enhances and provides measurable business value*/}
        {/*          to organizations globally through the most effective use of*/}
        {/*          technology and resources.*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    /!* Right End *!/*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Content;
